import { useTranslation } from 'react-i18next';

import { TinyUserText } from '@/components/shared/TinyUserText';
import { BodyProps } from '@/components/shared/typography/Body';
import { formatDateToDefaultFormat } from '@/utils/date/formatDate';
import { getWordByIndex } from '@/utils/string/getWordByIndex';
import type { Nullable } from '@/types/shared';

type ActionInfoByUserProps = {
  actionBy: Nullable<string>;
  text?: Nullable<string>;
  nameBodySize?: BodyProps['size'];
};

export const ActionInfoByUser = ({
  actionBy,
  text,
  nameBodySize = 'small',
}: ActionInfoByUserProps) => {
  const { t } = useTranslation('common');
  const textToDisplay =
    text === undefined
      ? undefined
      : formatDateToDefaultFormat(text) || t('noUpdates');

  return (
    <TinyUserText
      firstName={getWordByIndex(actionBy, 0)}
      lastName={getWordByIndex(actionBy, 1)}
      subText={textToDisplay}
      nameBodySize={nameBodySize}
    />
  );
};
