export enum AuthMethodType {
  Password = 'Username/Password',
  Google = 'Google',
}

export enum CompanyType {
  Admin = 0,
  Customer = 1,
}

export enum UserRoles {
  Admin = 'Admin',
  User = 'User',
  Guest = 'Guest',
}

export enum ViewMode {
  List = 'list',
  Grid = 'grid',
  Chart = 'chart',
}

export enum BotRunStatus {
  OK = 'OK',
  Stopped = 'Stopped',
  Failed = 'Failed',
  Running = 'Running'
}

export enum BotSlowRun {
  Slow = 'slow',
  NotSlow = 'notSlow'
}

export enum OS {
  Windows,
  Other,
}

export enum JointCheckboxState {
  Checked = 'checked',
  Ambiguous = 'ambiguous',
}

export enum TestPurchaseStatus {
  Created = 'Created',
  Requested = 'Requested',
  Completed = 'Completed',
}

export enum EnforcementStatus {
  Created = 'Created',
  VerificationRequested = 'Verification Requested',
  AwaitingReview = 'Awaiting Review',
  TakedownRequested = 'Takedown Requested',
  Completed = 'Completed',
  Retracted = 'Retracted',
  Cancelled = 'Cancelled'
}

export enum EnforcementAuditTrailHistoryType {
  Created = 'Created',
  InitialNotificationSentToReseller = 'Initial notification sent to reseller',
  RequestReceivedByReseller = 'Request received by reseller',
  ResponseReceivedFromReseller = 'Response received from reseller',
  TakeDownRequested = 'Take-down requested',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Paused = 'Paused',
  Resumed = 'Resumed',
  Retracted = 'Retracted',
  RequestDeliveredToReseller = 'Request delivered to reseller',
  RequestNotDeliverableToReseller = 'Request not deliverable to reseller',
}

export enum StoreStatus {
  ActionInProgress = 'Action in progress',
  Greylisted = 'Greylist',
  Allowed = 'Allowed'
}

export enum BotScheduledInfo {
  Scheduled = 'scheduled',
  NotScheduled = 'notScheduled'
}
