import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { Body } from '@/components/shared/typography/Body';
import { Header } from '@/components/shared/typography/Header';
import { Button, ButtonIcon } from '@/components/shared/buttons';
import { Tooltip } from '@/components/shared/Tooltip';
import { AnimatedMedia } from '@/components/shared/AnimatedMedia';
import { useGoBack } from '@/hooks/shared';
import MagnifyingGlass from '@/assets/images/magnifying_glass.svg';
import type { AnimatedMediaName } from '@/types/shared';

import style from './ErrorPage.module.sass';

type ErrorPageBaseProps = {
  error?: Error;
  title?: string;
  subtitle?: string;
  resetError?: () => void;
  customControl?: ReactNode;
  imageUrl?: string | null;
};

type ErrorPagePlaceholderProps = {
  animatedMediaName?: AnimatedMediaName;
  imageUrl?: never;
} | {
  animatedMediaName?: never;
  imageUrl?: string | null;
};

type ErrorPageProps = ErrorPageBaseProps & ErrorPagePlaceholderProps;

export const ErrorPage = ({
  error,
  title,
  subtitle,
  resetError,
  customControl,
  animatedMediaName,
  imageUrl = MagnifyingGlass,
}: ErrorPageProps) => {
  const { t } = useTranslation();
  const { goBack, willGoBackNavigate } = useGoBack();

  const handleGoBack = () => {
    goBack();
    resetError && resetError();
  };

  const renderMessage = () => {
    if (subtitle) return subtitle;
    if (error) return t('common:errors.somethingWentWrongError');
    return t('common:errors.pageError.subHeader');
  };

  const renderDevError = () => {
    if (!error || import.meta.env.PROD) {
      return null;
    }

    return (
      <div>
        <code>
          {error.message}
        </code>
      </div>
    );
  };

  const renderButton = () => {
    if (customControl) {
      return customControl;
    }

    if (!willGoBackNavigate) {
      return (
        <Button
          onClick={handleGoBack}
          sizeVariant='small'
          iconName='ArrowLeft'>
          {t('common:errors.navigateToHome')}
        </Button>
      );
    }

    return (
      <Tooltip content={t('common:goBack')}>
        <div>
          <ButtonIcon
            className={style.backButton}
            iconName='ArrowLeft'
            iconClassName={style.icon}
            onClick={handleGoBack}
            variant='primary'
          />
        </div>
      </Tooltip>
    );
  };

  const renderPlaceholder = () => {
    if (typeof imageUrl === 'string') {
      return (
        <img
          className={style.image}
          src={imageUrl}
        />
      );
    }

    if (animatedMediaName) {
      return (
        <AnimatedMedia
          name={animatedMediaName}
          className={style.image}
        />
      );
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.circle}></div>
      <div className={style.circle}></div>
      <div className={style.circle}></div>
      <div className={style.wrapperInner}>
        {renderPlaceholder()}
        <Header variant='h2' className={style.header}>
          {title ?? t('common:errors.pageError.header')}
        </Header>
        <Body size='medium' className={style.bodyText}>
          {renderMessage()}
          {renderDevError()}
        </Body>
        {renderButton()}
      </div>
    </div>
  );
};
