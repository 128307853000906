import { FC, ReactElement } from 'react';

import { useBranding } from '@/hooks/branding';

type BrandingGatewayProps = {
  children: ReactElement;
};

export const BrandingGateway: FC<BrandingGatewayProps> = ({
  children,
}) => {
  const {
    isLoading,
    error,
  } = useBranding();

  if (isLoading) {
    return null;
  }

  if (error) {
    return (
      <>
        Error
      </>
    );
  }

  return (
    <>{children}</>
  );
};
