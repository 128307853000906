import { useDataQuery } from '@/hooks/shared';
import { fetchBranding } from '@/queries/branding';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { type Branding } from '@/types/branding';

export const useBranding = () => {
  const {
    data: branding,
    ...rest
  } = useDataQuery(QUERY_KEYS.branding, fetchBranding, {
    suspense: true,
    refetchOnMount: false,
    select: (brandingInfo) => {
      // Our type `branding.attributes.companyId` is a string, but the server sends it as a number.
      // Changing the type would require many client-side changes.
      // Temporary workaround.
      const {
        attributes,
        ...restData
      } = brandingInfo;

      const brandingData: Branding = {
        ...restData,
        attributes: {
          ...attributes,
          companyId: String(attributes.companyId),
        },
      };

      return brandingData;
    },
  });

  return {
    branding,
    ...rest,
  };
};
