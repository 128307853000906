import { type PropsWithChildren } from 'react';
import {
  ErrorBoundary as SentryErrorBoundary,
  type FallbackRender,
} from '@sentry/react';

import { ErrorPage } from '@/components/ErrorPage';

type ErrorBoundaryProps = PropsWithChildren;

const renderFallback: FallbackRender = ({ error, resetError }) => {
  return (
    <ErrorPage
      error={error}
      resetError={resetError}
    />
  );
};

export const ErrorBoundary = ({
  children,
}: ErrorBoundaryProps) => {
  return (
    <SentryErrorBoundary fallback={renderFallback}>
      {children}
    </SentryErrorBoundary>
  );
};
